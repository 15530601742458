import React, { useState, useEffect } from 'react';
import { 
  Tabs, Space, Button, Table, Input, DatePicker, InputNumber, Form, message, Row, Col, Card, Typography, Popover, Select 
} from "antd";
import { SearchOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import axios from "axios";
import { useMediaQuery } from 'react-responsive';

const { Title, Text, TextArea } = Typography;

export function 給付系統() {


  


  // State declarations
  const [起始日, set_起始日] = useState("");
  const [結束日, set_結束日] = useState("");
  const [結束日_顯示用, set_結束日_顯示用] = useState("");
  const [應上時數, set_應上時數] = useState(0);
  const [考勤列表, set_考勤列表] = useState([]);
  const [考勤對象, set_考勤對象] = useState("");
  const [考勤時數, set_考勤時數] = useState(0);
  const [時數明細, set_時數明細] = useState([]);
  const [給付歷史, set_給付歷史] = useState([]);
  const [給付明細, set_給付明細] = useState([]);
  const [出勤紀錄, set_出勤紀錄] = useState([]);
  const [給付總額, set_給付總額] = useState(0);
  

  const [form_打卡清單] = Form.useForm();
  const [form_考勤確認] = Form.useForm();
  const [form_一般結算] = Form.useForm();
  const [form_特別結算] = Form.useForm();
  const [form_特別結算_3] = Form.useForm();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const list_打卡類型 =[{value:"🟢上班",label:"🟢上班"},{value:"🔴下班",label:"🔴下班"},{value:"🟡補卡",label:"🟡補卡"}]

  // API calls
  const apiCall = async (url, data) => {
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      message.error('An error occurred. Please try again.');
      console.error('API call error:', error);
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleString('zh-TW', {year: 'numeric', month: 'numeric', day: 'numeric'});
  };

  const Link_讀取_時數明細 = async (夥伴, 結束日) => {
    const data = await apiCall("https://pos-back-end.pktriot.net/8vDAe4FR", { 結束日, 夥伴 });
    set_時數明細(data.map(item => ({ ...item, 期別: formatDate(item.期別) })));
  };

  const Link_讀取_給付歷史 = async (夥伴, 起始日, 結束日) => {
    const data = await apiCall("https://pos-back-end.pktriot.net/VMMX3kvx", { 起始日, 結束日, 夥伴 });
    set_給付歷史(data.map(item => ({ ...item, 期別: formatDate(item.期別) })));
  };

  const Link_讀取_給付明細 = async (夥伴, 起始日, 結束日) => {
    const data = await apiCall("https://pos-back-end.pktriot.net/aGRzmpYU", { 起始日, 結束日, 夥伴 });
    set_給付明細(data.map(item => ({ ...item, 期別: formatDate(item.期別) })));
  };

  const Link_讀取_所有給付明細 = async (起始日, 結束日) => {
    const data = await apiCall("https://pos-back-end.pktriot.net/XVDyUR9P", { 起始日, 結束日 });
    return data.map(item => ({ ...item, 期別: formatDate(item.期別) }));
  };
  
  const Link_讀取_區間內出勤列表 = async(考勤對象, 起始日, 結束日) => {
    let response = await axios.post("https://pos-back-end.pktriot.net/UGgPQKYn",{
      起始日: 起始日,
      結束日: 結束日,
      考勤對象: 考勤對象,
    })
  
    Object.keys(response.data)
    .forEach((key) => {const timestamp = response.data[key].打卡時間;response.data[key].打卡時間 = new Date(timestamp)
    .toLocaleString('zh-TW', {hour12: false})
    .replace(/\//g, "-")})
  
    const sortedData = response.data.sort((a, b) => new Date(b.打卡時間) - new Date(a.打卡時間));
    set_出勤紀錄(sortedData)
  
  }


  const API_更新_核准單項考勤 = async(table_object) => {
    await axios.post("https://pos-back-end.pktriot.net/WwsrWuCP",{
      ID:table_object.ID,
    })
    const new_item = {
      ID:table_object.ID,
      門市:table_object.門市,
      打卡時間:table_object.打卡時間,
      打卡類型:table_object.打卡類型,
      正常時數:table_object.正常時數,
      考勤:"✔️",
      備註:table_object.備註,
    }
  
    set_出勤紀錄(出勤紀錄.map(item => 
      item.ID === table_object.ID
        ? new_item
        : item
    ));
  
  
  }
  const update_punch = async(table_object) => {
    await axios.post("https://pos-back-end.pktriot.net/NBACM_0103",{
      ID:table_object.ID,
      打卡類型:table_object.打卡類型,
      正常時數:table_object.正常時數,
      考勤: "更新",
      備註: table_object.備註,
    })
    const new_item = {
      ID:table_object.ID,
      門市:table_object.門市,
      打卡時間:table_object.打卡時間,
      打卡類型:table_object.打卡類型,
      正常時數:table_object.正常時數,
      考勤: "更新",
      備註:table_object.備註,
    }
  
    set_出勤紀錄(出勤紀錄.map(item => 
      item.ID === table_object.ID
        ? new_item
        : item
    ));
  
  
  }
  const delete_punch = async(table_object) => {
    await axios.post("https://pos-back-end.pktriot.net/NBACM_0104",{ ID:table_object.ID, })
  
    set_出勤紀錄(出勤紀錄.filter(item => item.ID !== table_object.ID ));
  
  
  }



  const renderPopoverContent = (table_object) => (
    <Form form={form_打卡清單} name="編輯打卡" onFinish={update_punch}>
      <Space direction="vertical" align="start">
        <Space direction="vertical" align="start">
          <Form.Item name="ID" hidden></Form.Item>
          <Form.Item name="打卡時間" label="打卡時間">
            <Input disabled />
          </Form.Item>
          <Form.Item name="門市" label="打卡門市">
            <Input disabled />
          </Form.Item>
          <Form.Item name="打卡類型" label="打卡類型" rules={[{ required: true, message: '必填欄位' }]}>
            <Select style={{width: 100}} suffixIcon={<UserOutlined />} options={list_打卡類型} />
          </Form.Item>
          <Form.Item name="正常時數" label="正常時數" rules={[{ required: false }]}>
            <Input style={{ width: 100 }} type="number" />
          </Form.Item>
        </Space>
        <Space direction="horizontal" align="start">
          <Form.Item name="備註" label="備註" rules={[{ required: false }]}>
            <Input style={{ width: 120 }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">確認</Button>
          </Form.Item>
        </Space>
      </Space>
    </Form>
  );



  const columns_出勤紀錄 = [
    { title: '門市', dataIndex: '門市', key: '0' },
    { title: '時間', dataIndex: '打卡時間', key: '1' },
    { title: '類型', dataIndex: '打卡類型', key: '2' },
    { title: '正常', dataIndex: '正常時數', key: '3' },
    { title: '考勤', dataIndex: '考勤', key: '4' },
    { title: '備註', dataIndex: '備註', key: '5' },
    {
      title:'操作', dataIndex: '操作', key: '6',
  
      render: (_,table_object) => {
        return( 
        <span>
          <Typography.Link onClick={() =>{API_更新_核准單項考勤(table_object)} } style={{marginRight: 8}}> ✅考勤 </Typography.Link>
           <Popover
          content={renderPopoverContent(table_object)}
          trigger="click"
        >
          <Typography.Link
            onClick={() => { 
              form_打卡清單.setFieldsValue({
                ID: table_object.ID,
                門市: table_object.門市,
                打卡時間: table_object.打卡時間,
                打卡類型: table_object.打卡類型,
                正常時數: table_object.正常時數,
                考勤: table_object.考勤,
                備註: table_object.備註,
              });
            }}
            style={{marginRight: 8}}
          >
            ⚠️更新
          </Typography.Link>
        </Popover>
            <Typography.Link onClick={() =>{delete_punch(table_object)} } style={{marginRight: 8}}> ❌刪除 </Typography.Link>
        </span>
        )
      }
    } 
  ];

  // Event handlers 
  const handleDateChange = (dates, dateStrings) => {
    set_起始日(dateStrings[0]);
    set_結束日(dateStrings[1]);
    set_結束日_顯示用(dateStrings[1]);
  };

  const Step_01_考勤區間確認 = async () => {
    try {
      const 起始日_日期化 = new Date(起始日);
      const 結束日_日期化 = new Date(結束日);
      const 區間天數 = Math.floor((結束日_日期化 - 起始日_日期化) / (1000 * 60 * 60 * 24));
      const 理論時數 = (區間天數 - 7) * 8;
      set_應上時數(理論時數);

      let response = await apiCall("https://pos-back-end.pktriot.net/TVVfMXfR", { 起始日, 結束日 });
      
      // 過濾掉考勤規則包含 "❌" 的項目
      let filteredData = response.filter(item => !item.考勤規則 || !item.考勤規則.includes('❌'));

      let sortedData = filteredData.sort((a, b) => {
        const memberIdA = parseInt(a.考勤序號.split('-')[1]);
        const memberIdB = parseInt(b.考勤序號.split('-')[1]);
        return memberIdA - memberIdB;
      });

      let payDetails = await Link_讀取_所有給付明細(起始日, 結束日);

      sortedData = sortedData.map(item => ({
        ...item,
        結算狀態: payDetails.some(payItem => payItem.夥伴 === item.考勤對象) ? '✔️已結算' : '未處理'
      }));

      set_考勤列表(sortedData);
    } catch (error) {
      console.error('考勤區間確認錯誤:', error);
      message.error('考勤區間確認時發生錯誤，請稍後再試。');
    }
  };

  const Step_02_選擇考勤對象 = {
    onChange: async (selectedRowKeys, selectedRowsdata) => {
      const selectedData = selectedRowsdata[0];
      set_考勤對象(selectedData.考勤對象);
      set_考勤時數(selectedData.已考勤時數);
      await Promise.all([
        Link_讀取_時數明細(selectedData.考勤對象, 起始日, 結束日),
        Link_讀取_給付明細(selectedData.考勤對象, 起始日, 結束日),
        Link_讀取_給付歷史(selectedData.考勤對象, 起始日, 結束日),
        Link_讀取_區間內出勤列表(selectedData.考勤對象, 起始日, 結束日)
      ]);
    }
  };

  const Step_21_一般結算 = async (values) => {
    await apiCall("https://pos-back-end.pktriot.net/qhKSWqah", {
      期別: 結束日,
      夥伴: 考勤對象,
      項目: "月結算",
      時數: parseFloat(考勤時數) - parseFloat(應上時數) + parseFloat(values.時數調整 || 0),
      備註: "系統",
    });
    await Link_讀取_時數明細(考勤對象, 起始日, 結束日);
    message.success('一般結算完成');
  };

  const Step_22_特別結算 = async (values) => {
    await apiCall("https://pos-back-end.pktriot.net/qhKSWqah", {
      期別: 結束日,
      夥伴: 考勤對象,
      項目: values.項目,
      時數: parseFloat(values.時數) || 0,
      備註: values.備註,
    });
    await Link_讀取_時數明細(考勤對象, 起始日, 結束日);
    message.success('特別結算完成');
  };

  const Step_32_特別結算 = async (values) => {
    await apiCall("https://pos-back-end.pktriot.net/XNgPEQ5n", {
      期別: 結束日,
      夥伴: 考勤對象,
      項目: values.項目,
      金額: parseFloat(values.金額) || 0,
      備註: values.備註,
    });

    message.success('特別給付結算完成');
  };
  const Step_33_選擇給付歷史 = {
    onChange: async (selectedRowKeys, selectedRowsdata) => {
      await Step_32_特別結算(selectedRowsdata[0])
      const updatedPaymentDetails = await Link_讀取_給付明細(考勤對象, 起始日, 結束日);
      const totalPayment = updatedPaymentDetails.reduce((total, item) => total + (parseFloat(item.金額) || 0), 0);
      await set_給付總額(totalPayment);
      }
    ,
  };
  // Table columns
  const columns_考勤列表 = [
    { title: '考勤序號', dataIndex: '考勤序號', key: '考勤序號' },
    { title: '考勤對象', dataIndex: '考勤對象', key: '考勤對象' },
    { title: '未考勤時數', dataIndex: '未考勤時數', key: '未考勤時數' },
    { title: '已考勤時數', dataIndex: '已考勤時數', key: '已考勤時數' },
    { title: '考勤規則', dataIndex: '考勤規則', key: '考勤規則' },
    { title: '結算狀態', dataIndex: '結算狀態', key: '結算狀態' },
  ];

  const columns_時數明細 = [
    { title: '期別', dataIndex: '期別', key: '期別' },
    { title: '項目', dataIndex: '項目', key: '項目', ellipsis: true },
    { 
      title: '時數', 
      dataIndex: '時數', 
      key: '時數',
      render: (text) => {
        const color = text > 0 ? '#4CAF50' : (text < 0 ? '#F44336' : 'inherit');
        return <Text strong style={{ color }}>{text}</Text>;
      }
    },
    { 
      title: '累積', 
      dataIndex: '累積', 
      key: '累積',
      render: (text) => {
        const color = text > 0 ? '#4CAF50' : (text < 0 ? '#F44336' : 'inherit');
        return <Text strong style={{ color }}>{text}</Text>;
      }
    },
    { 
      title: '備註', 
      dataIndex: '備註', 
      key: '備註',
      render: (text) => <Text strong style={{ color: '#FFC107' }}>{text}</Text>
    },
  ];

  const columns_給付明細 = [
    { title: '期別', dataIndex: '期別', key: '期別', width: 120 },
    { title: '項目', dataIndex: '項目', key: '項目', ellipsis: true, width: 120 },
    { 
      title: '金額', 
      dataIndex: '金額', 
      key: '金額', 
      width: 120,
      render: (text) => {
        const formattedAmount = new Intl.NumberFormat('zh-TW', { style: 'decimal' }).format(text);
        const color = text > 0 ? '#4CAF50' : (text < 0 ? '#F44336' : 'inherit');
        return <Text strong style={{ color }}>{formattedAmount}</Text>;
      }
    },
    { 
      title: '備註', 
      dataIndex: '備註', 
      key: '備註',
      render: (text) => <Text strong style={{ color: '#FFC107' }}>{text}</Text>
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: '100%', padding: 16 }}>
      <Title level={2}>員工給付系統</Title>
      
      <Card title="考勤查詢">
        <Form form={form_考勤確認} onFinish={Step_01_考勤區間確認} layout="vertical">
              <Form.Item name="dateRange" label="考勤期間" rules={[{ required: true, message: '請選擇日期範圍' }]}>
                <DatePicker.RangePicker style={{ width: 300 }} onChange={handleDateChange} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{ width: 100 }}>
                  查詢
                </Button>
              </Form.Item>

        </Form>
      </Card>

      <Card title="考勤列表">
      <Space direction="vertical" style={{ width: '100%' }}>
        <Table 
          columns={columns_考勤列表} 
          dataSource={考勤列表} 
          rowKey="考勤序號"
          rowSelection={{ type: "radio", ...Step_02_選擇考勤對象 }}
          scroll={{ x: true }}
          size={isMobile ? "small" : "middle"}
        />
        {出勤紀錄.length > 0 && (
          <Table 
            title={() => '出勤紀錄'}
            columns={columns_出勤紀錄}
            dataSource={出勤紀錄}
            rowKey={(record) => record.ID} 
            scroll={{ x: true }}
            size={isMobile ? "small" : "middle"}
            pagination={{ pageSize: 100 }}
          />
        )}
      </Space>
    </Card>

      {考勤對象 && (
        <>
          <Card title="時數明細">
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Text>考勤期別：{結束日_顯示用}</Text>
                  <Text>考勤對象：{考勤對象}</Text>
                  <Text>應上時數：{應上時數}</Text>
                  <Text>實上時數：{考勤時數}</Text>
                  <Text>時數差額：{考勤時數 - 應上時數}</Text>
                </Space>
                <Form form={form_一般結算} onFinish={Step_21_一般結算} layout="inline" style={{ marginTop: 16 }}>
                  <Form.Item name="時數調整">
                    <InputNumber placeholder="調整時數" style={{ width: 100 }} />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
                      一般結算
                    </Button>
                  </Form.Item>
                </Form>
                <Form form={form_特別結算} onFinish={Step_22_特別結算} layout="vertical" style={{ marginTop: 16 }}>
                  <Form.Item name="項目" rules={[{ required: true, message: '請輸入項目' }]}>
                    <Input placeholder="項目" />
                  </Form.Item>
                  <Form.Item name="備註">
                    <Input placeholder="備註" />
                  </Form.Item>
                  <Form.Item name="時數" rules={[{ required: true, message: '請輸入時數' }]}>
                    <InputNumber placeholder="時數" style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<PlusOutlined />} style={{ width: '100%' }}>
                      特別結算
                    </Button>
                    </Form.Item>
                </Form>
              </Col>
              <Col xs={24} md={16}>
                <Table 
                  columns={columns_時數明細} 
                  dataSource={時數明細} 
                  pagination={false}
                  scroll={{ x: true }}
                  size={isMobile ? "small" : "middle"}
                />
              </Col>
            </Row>
          </Card>

          <Card title="薪資明細">
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Text>給付期別：{結束日_顯示用}</Text>
                  <Text>給付對象：{考勤對象}</Text>
                </Space>
                <Form form={form_特別結算_3} onFinish={Step_32_特別結算} layout="vertical" style={{ marginTop: 16 }}>
                  <Form.Item name="項目" rules={[{ required: true, message: '請輸入項目' }]}>
                    <Input placeholder="項目" />
                  </Form.Item>
                  <Form.Item name="備註">
                    <Input placeholder="備註" />
                  </Form.Item>
                  <Form.Item name="金額" rules={[{ required: true, message: '請輸入金額' }]}>
                    <InputNumber placeholder="金額" style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<PlusOutlined />} style={{ width: '100%' }}>
                      特別結算
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={24} md={16}>
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="給付歷史" key="1">
                    <Table 
                      columns={columns_給付明細} 
                      dataSource={給付歷史} 
                      rowKey={(record) => record.序號} rowSelection={{ type: "radio", ...Step_33_選擇給付歷史, }}
                      scroll={{ x: true }}
                      size={isMobile ? "small" : "middle"}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="給付明細" key="2">
                  <Text>給付總額：{給付總額}</Text>
                    <Table 
                      columns={columns_給付明細} 
                      dataSource={給付明細} 
                      scroll={{ x: true }}
                      size={isMobile ? "small" : "middle"}
                      
                    />
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </Space>
  );
}