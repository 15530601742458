// #region import Zone
  
import Paper from '@mui/material/Paper';
import 'antd/dist/reset.css';
import { RadarChartOutlined,RedoOutlined ,MinusCircleOutlined,PlusCircleOutlined,UserOutlined,BankFilled,SignalFilled,DropboxCircleFilled,DollarCircleFilled,SwapOutlined,CheckOutlined,SearchOutlined,RetweetOutlined,InfoCircleOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , DatePicker, InputNumber , Tabs  , Progress,Typography,Descriptions,Form,Divider, Row ,Popover ,Col, Card, Statistic} from "antd";
import { useRef,useState,useEffect } from "react";
import Axios from "axios";
import moment from 'moment';
import React from 'react';

// #endregion

export function 報表系統(adminvalue){

  // const Tab_items = [
  //   {
  //     key: '0',
  //     label: (<span><RadarChartOutlined />分析</span>),
  //     children: <營業頁面 adminvalue={[adminvalue.adminvalue[0],adminvalue.adminvalue[1]]} />,
  //   },
    // {
    //   key: '1',
    //   label: (<span><DollarCircleFilled />金流</span>),
    //   children: <金流頁面 adminvalue={[adminvalue.adminvalue[0],adminvalue.adminvalue[1]]} />,
    // },
    // {
    //   key: '3',
    //   label: (<span><BankFilled />出勤</span>),
    //   children: <出勤頁面 adminvalue={[adminvalue.adminvalue[0],adminvalue.adminvalue[1]]} />,
    // } 
  // ]

  return (
    <div className="App"> 
      {/* <Tabs 
        size="large" 
        type="card" 
        style={{ 
          marginLeft: "10px", 
          marginRight: "10px",
          fontWeight: "bold", 
          borderRadius: "5px"
        }} 
        defaultActiveKey="0" 
        items={Tab_items}  
      /> */}

      <營業頁面 />

    </div>
  )
}

function 營業頁面(adminvalue)
{
//#region 初始日期與門市模組
const currentDate = new Date()  //currentDate.setDate(currentDate.getDate() - 15) 
const 今天 = moment(currentDate).format("YYYY-MM-DD")
const 前幾天 = moment(currentDate).subtract(1, 'days').format("YYYY-MM-DD")
const initial_object = {起始日:前幾天,結束日:今天}
//#endregion
//#region PROGRESS 模組
const [percent, setPercent] = useState(0);
const [percent_progessmsg, setpercent_progessmsg] = useState("選門市/區間");
//#endregion
//#region 品項 模組(R)
const [item_list , set_item_list] = useState([]);
const read_item = async() => {
  let response = await Axios.get("https://pos-back-end.pktriot.net/item_read") 
  const newarray = response.data.map((item) => {return {value:item.items_name,label:item.items_name ,}})
  set_item_list(newarray)
}
//#endregion
//#region 存貨 模組
const [dayinventory_list , set_dayinventory_list] = useState([]);
const read_dayinventory = async(form_object) => {
  const 起始日 = new Date(form_object.起始日);
  const 報表起始日 = new Date(起始日.setDate(起始日.getDate() - 1)).toLocaleString('zh-TW', {year: 'numeric', month: '2-digit', day: '2-digit',})
  let response = await Axios.put("https://pos-back-end.pktriot.net/dayinventory_read_pointall",{
    種類: form_object.種類,
    起始日: 報表起始日,
    結束日: form_object.結束日,
})
  const sortedData = response.data.sort((a, b) => new Date(b.日期) - new Date(a.日期));
  set_dayinventory_list(sortedData)
}
//#endregion 

//#region 物流 模組

const [logistic_list_summary, set_logistic_list_summary] = useState([]);
const read_logistic_summary = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/logistic_read_point",{
    門市: form_object.門市,
    起始日: form_object.起始日,
    結束日: form_object.結束日,
})
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp).toLocaleString('zh-TW', {hour12: false});});
  set_logistic_list_summary(response.data) 
}

//#endregion 
//#region LOGISTIC_ITEM 模組

const [logistic_list_item, set_logistic_list_item] = useState([]);
const read_logistic_item = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/logistic_read_item",{
    物品: form_object.種類,
    起始日: form_object.起始日,
    結束日: form_object.結束日,
})
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp).toLocaleString('zh-TW', {hour12: false});});
  set_logistic_list_item(response.data) 
}

  //#endregion 
//#region 日報 模組
const [report_list_summary , set_report_list_summary] = useState([]);
const [report_list_check , set_report_list_check] = useState([]);
const read_report_summary = async (form_object) => {
  const 起始日 = new Date(form_object.起始日);
  const 報表起始日 = new Date(起始日.setDate(起始日.getDate() - 1)).toLocaleString('zh-TW', {year: 'numeric', month: '2-digit', day: '2-digit',})
  let response = await Axios.put("https://pos-back-end.pktriot.net/dayreport_read_shareholder",{
    門市: form_object.門市,
    起始日: 報表起始日,
    結束日: form_object.結束日,
  })
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].日期;response.data[key].日期 = new Date(timestamp).toLocaleString('zh-TW', {hour12: false});});
  const sortedData = response.data.sort((a, b) => new Date(b.DAYREPORT_ID) - new Date(a.DAYREPORT_ID));
  set_report_list_summary(sortedData)
  
}






const [台幣總值 , set_台幣總值] = useState("-");
const [等價總值 , set_等價總值] = useState("-");
const [刮樂總值 , set_刮樂總值] = useState("-");
const [仟元總量 , set_仟元總量] = useState("-");

const read_report_check = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/dayreport_read_check",{
    起始日: form_object.起始日,
    結束日: form_object.結束日,
  })
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].日期;response.data[key].日期 = new Date(timestamp).toLocaleString('zh-TW', {year: 'numeric',month: '2-digit',day: '2-digit'});});
  const sortedData = response.data.sort((a, b) => new Date(b.DAYREPORT_ID) - new Date(a.DAYREPORT_ID));
  set_report_list_check(sortedData)



  const 台幣總值_cal = sortedData.reduce((accumulator, currentItem) => {return accumulator + (currentItem.台幣現值|| 0) },0).toLocaleString();
  const 等價總值_cal = sortedData.reduce((accumulator, currentItem) => {return accumulator + (currentItem.等價現值|| 0) },0).toLocaleString(); 
  const 刮樂總值_cal = sortedData.reduce((accumulator, currentItem) => {return accumulator + (currentItem.刮樂現值|| 0) },0).toLocaleString(); 
  const 仟元總量_cal = sortedData.reduce((accumulator, currentItem) => {return accumulator + (currentItem.現金1000|| 0) },0).toLocaleString();  

  set_台幣總值(台幣總值_cal)
  set_等價總值(等價總值_cal)
  set_刮樂總值(刮樂總值_cal)
  set_仟元總量(仟元總量_cal)

  
  
}
//#endregion
//#region SUMMARY 模組
const [summary_list , set_summary_list] = useState([]);
const [台彩總計 , set_台彩總計] = useState("-");
const [運彩總計 , set_運彩總計] = useState("-");
const [刮樂總計 , set_刮樂總計] = useState("-");
const [毛利總計 , set_毛利總計] = useState("-");

useEffect(() => { if (report_list_summary.length > 0) { 


      var summarylist = []
      const listLength = report_list_summary.length

      for (let i = 0; i < listLength-1 ; i++) {

        const currentDate = new Date(report_list_summary[i+1].日期); currentDate.setHours(currentDate.getHours() + 25);
        const 日期 = currentDate.toLocaleString('zh-TW', {year: 'numeric',month: '2-digit',day: '2-digit'}).replace(/\//g, "-")
        const 門市 = report_list_summary[i].門市
        
        const 物流增加 = logistic_list_summary
                      .filter((item)=>{ const formattedDate = new Date(item.TIMESTAMP_ID).toLocaleDateString("zh-TW", {year: "numeric",month: "2-digit", day: "2-digit",}).replace(/\//g, "-"); return formattedDate === 日期  })
                      .reduce((accumulator, currentItem) => {return accumulator + (currentItem.額度增加|| 0) },0)
        const 物流減少 = logistic_list_summary
                      .filter((item)=>{ const formattedDate = new Date(item.TIMESTAMP_ID).toLocaleDateString("zh-TW", {year: "numeric",month: "2-digit", day: "2-digit",}).replace(/\//g, "-"); return formattedDate === 日期  })
                      .reduce((accumulator, currentItem) => {return accumulator + (currentItem.額度減少|| 0) },0)   


        const 台彩銷售_cal = report_list_summary[i].台彩銷售
        const 運彩銷售_cal = report_list_summary[i].運彩銷售        
        const 刮樂銷售_cal = report_list_summary[i+1].刮樂現值-report_list_summary[i].刮樂現值+物流增加-物流減少 ; 
        const 預估毛利_cal = (台彩銷售_cal+刮樂銷售_cal)*0.08+運彩銷售_cal*0 

        const 台彩銷售 = Math.floor(台彩銷售_cal).toLocaleString();
        const 運彩銷售 = Math.floor(運彩銷售_cal).toLocaleString(); 
        const 刮樂銷售 = Math.floor(刮樂銷售_cal).toLocaleString();
        const 預估毛利 = Math.floor(預估毛利_cal).toLocaleString();

        summarylist.push({
          DAYREPORT_ID: report_list_summary[i].DAYREPORT_ID,
          日期: 日期,
          門市: 門市,

          台彩銷售:台彩銷售,
          運彩銷售:運彩銷售,
          刮樂銷售:刮樂銷售,
          預估毛利:預估毛利,

          台彩銷售_cal:台彩銷售_cal,
          運彩銷售_cal:運彩銷售_cal,
          刮樂銷售_cal:刮樂銷售_cal,
          預估毛利_cal:預估毛利_cal,

      
        })}

      set_summary_list(summarylist);setPercent(100)
      
      const 台彩總計_cal = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem.台彩銷售_cal|| 0) },0).toLocaleString(); 
      const 運彩總計_cal = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem.運彩銷售_cal|| 0) },0).toLocaleString();
      const 刮樂總計_cal = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem.刮樂銷售_cal|| 0) },0).toLocaleString();
      const 毛利總計_cal = Math.floor(summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem.預估毛利_cal|| 0) },0)).toLocaleString();
      
      set_台彩總計(台彩總計_cal)
      set_運彩總計(運彩總計_cal)
      set_刮樂總計(刮樂總計_cal)
      set_毛利總計(毛利總計_cal)

      setpercent_progessmsg("分析完成...")

    }

}, [report_list_summary]);
//#endregion


//#region Table 模組
const columns_summary = [
  {
      Key:'0',
      title:'日期',
      dataIndex:'日期', width: 110
    },
    {
      Key:'2',
      title:'台彩',
      dataIndex:'台彩銷售',width: 80
    },
    {
      Key:'3',
      title:'運彩',
      dataIndex:'運彩銷售',width: 80
    },
    {
      Key:'4',
      title:'刮樂',
      dataIndex:'刮樂銷售',width: 80
    },
];
//#endregion
//#region LOAD 模組
const preloadData = async () => {
  await read_member()
  await read_item()
  await read_report_check(initial_object)
}
useEffect(() => {preloadData()}, [])



const loadData = async (form_object) => { 
  
  setPercent(10);setpercent_progessmsg("初始化中...")
  await read_logistic_summary(form_object)
  setPercent(30);setpercent_progessmsg("讀取報表...")
  await read_report_summary(form_object)
  setPercent(50);setpercent_progessmsg("分析整合中...")
}


// #endregion

//#region Member 模組
const [member_list_point , set_member_point_list] = useState([]);
const [member_list_staff , set_member_staff_list] = useState([]);
const read_member = async() => {
let response = await Axios.post("https://pos-back-end.pktriot.net/gBYb95SH", {
  門市: "SHs - 劉適華",
})
const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})

const staff = newarray.filter((item) => item.label.startsWith('ST'));
set_member_staff_list(staff);

const point = newarray.filter((item) => item.label.startsWith('PO'));
set_member_point_list(point)
}

//#endregion

  return (
    <div>
      <Form name="查詢報表" onFinish={(form_object) => loadData(form_object)}>
      <Space direction='vertical' style={{width: '100%', padding: '10px', alignItems: 'center'}}>
        <Progress type="circle" percent={percent} size={60} style={{marginBottom: '10px'}} />
        <Typography.Text>{percent_progessmsg}</Typography.Text>
  
          
          <Form.Item name="門市"  rules={[{ required: true, message: '必填欄位' }]}>
            <Select 
                style={{width: '100%', minWidth: '150px'}} 
                suffixIcon={<UserOutlined/>} 
                placeholder="門市" 
                optionFilterProp="children" 
                filterOption={(input, option) =>(option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} 
                options={member_list_point} 
              />
          </Form.Item>
          
          <Form.Item name="起始日" rules={[{ required: true, message: '必填欄位' }]}>
            <DatePicker style={{width: '100%'}} format="YYYY-MM-DD" placeholder="起始日" />
          </Form.Item>
          
          <Form.Item name="結束日" rules={[{ required: true, message: '必填欄位' }]}>
            <DatePicker style={{width: '100%'}} format="YYYY-MM-DD" placeholder="結束日" />
          </Form.Item>
          
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{width: '100%'}}>
              查詢
            </Button>
          </Form.Item>
        </Space>
      </Form>

      <Divider>總計</Divider>
      
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Card size="small">
            <Statistic title="台彩" value={台彩總計} />
          </Card>
        </Col>
        <Col span={12}>
          <Card size="small">
            <Statistic title="運彩" value={運彩總計} />
          </Card>
        </Col>
        <Col span={12}>
          <Card size="small">
            <Statistic title="刮樂" value={刮樂總計} />
          </Card>
        </Col>
        <Col span={12}>
          <Card size="small">
            <Statistic title="毛利(不含運彩，台刮8%)" value={毛利總計} />
          </Card>
        </Col>
      </Row>

      <Divider>詳細數據</Divider>
      
      <Table 
        columns={columns_summary} 
        dataSource={summary_list} 
        rowKey={(record) => record.DAYREPORT_ID}
        scroll={{ x: true }}
      />
    </div>
  )
}
