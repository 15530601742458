import { Menu , Button, DatePicker, Table , Tabs , Space } from "antd";
import "./App.css";
import 'antd/dist/reset.css';
import { SearchOutlined , HomeOutlined, AimOutlined } from '@ant-design/icons';
import { useState } from "react";
import { Link } from "react-router-dom";

export function NavBar(adminvalue){
  const 版本號 = 'v3.5'

    const Menu_items_0 = [
      {
        label: ("［"+版本號+"］［"+(adminvalue.adminvalue[1])+"］" ),
        key: '0',
      },
    ];
    const Menu_items_1 = [
        {
          label: ("［"+版本號+"］［"+(adminvalue.adminvalue[1])+"］［"+(adminvalue.adminvalue[0])+"］" ),
          key: '0',
        },
        {
          label: (<Link to ='/'>首頁</Link> ),
          key: '1',
          icon: <HomeOutlined />,
          className: 'menu-item' // 新增 className 屬性
        },
        {
          label: (<Link to ='本日帳務'>本日帳務</Link> ),
          key: '2',
          icon: <AimOutlined />,
        },
        {
          label: (<Link to ='查詢報表'>查詢報表</Link> ),
          key: '5',
          icon: <SearchOutlined />,
        },
        {
          label: (<Link to ='查詢會員'>查詢會員</Link> ),
          key: '6',
          icon: <SearchOutlined />,
        },
    ];
    const Menu_items_2 = [
      {
        label: ("［"+版本號+"］［"+(adminvalue.adminvalue[1])+"］" ),
        key: '0',
      },
      {
        label: (<Link to ='/'>首頁</Link> ),
        key: '1',
        icon: <HomeOutlined />,
        className: 'menu-item' // 新增 className 屬性
      },
    ];
    const Menu_items_9 = [
      {
        label: ("［"+版本號+"］［無效授權碼］" ),
        key: '0',
      },
    ];

    const [current, setCurrent] = useState('Homepage');
    const onClick = (e) => {setCurrent(e.key);}


    switch (adminvalue.adminvalue[2]) {
      case '門市':
        return (
          <>
            <Menu
              className="navbar"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={Menu_items_1}
            />
          </>
        );
      case '夥伴':
        return (
          <>
            <Menu
              className="navbar"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={Menu_items_2}
            />
          </>
        );
      case '股東':
        return (
          <>
            <Menu
              className="navbar"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={Menu_items_2}
            />
          </>
        );
      case '人資':
        return (
          <>
            <Menu
              className="navbar"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={Menu_items_2}
            />
          </>
        );
      case '總部':
        return (
          <>
            <Menu
              className="navbar"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={Menu_items_2}
            />
          </>
        );
      case undefined:
        return (
          <>
            <Menu
              className="navbar"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={Menu_items_9}
            />
          </>
        );
      default:
        return (
          <>
            <Menu
              className="navbar"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={Menu_items_0}
            />
          </>
        );
    }
  


}

